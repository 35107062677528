import { Component, Vue } from 'vue-property-decorator';

// API
import { getTasksFilters } from '@/utils/Api';

// Services
import ErrorService from '@/utils/ErrorService';

// Utils
import { first, isEmpty, get } from 'lodash';
import { isDateOverdue } from '@/utils/Helper';
import { Filter, FilterElementSlot, FilterPanelSlotType } from '@/utils/Filter';

// Enums
import DesignTaskStatus, { DesignTaskStatusExecutor } from '@/ship/Enums/DesignTaskStatus';

// Interfaces
import { DesignTask, IDesignFilters, IDesignFiltersItem, IDesignTaskFilters } from '@/ship/Models/Design';
import { DesignTaskFilter, DesignTaskTopFilter } from '@/ship/Models/DesignFilters';

interface TopFilterOptions extends IDesignTaskFilters {
    taskStatuses: IDesignFiltersItem[];
    executorStatuses: IDesignFiltersItem[];
}

// Types
type TaskShort = Pick<DesignTask, 'status' | 'deadline'>;

@Component
export default class VDesigningTaskFiltersMixin extends Vue {
    currentFilter = this.$t('page.designing.sideForm.all');
    currentFilterStatus: number | undefined = undefined;
    tasks: DesignTask[] = [];

    topFilters: DesignTaskTopFilter = {
        cities: [],
        projects: [],
        createdDateFrom: '',
        deadlineFrom: '',
        createdDateTo: '',
        deadlineTo: '',
        taskStatus: 0,
        authors: [],
        executorStatus: 0,
        executors: [],
    };
    topFilterOptions: TopFilterOptions | null = null;
    topFiltersList: FilterElementSlot[] = [];
    showTopFilter = false;

    createdDateFrom = '';
    createdDateTo = '';
    deadlineFrom = '';
    deadlineTo = '';
    taskStatus = 0;
    executorStatus = 0;

    readonly selectorBind = {
        label: 'name',
        multiple: true,
        trackBy: 'id',
        reduce: (x: unknown) => get(x, 'id'),
    };

    tasksShort: TaskShort[] = [];

    get filteredTasks(): DesignTask[] {
        const filter = this.filters.find(({ label }) => label === this.currentFilter)!.value;
        return this.tasks.filter((task) => filter(task));
    }

    get filters(): DesignTaskFilter[] {
        return [
            {
                label: this.$t('page.designing.sideForm.all'),
                value: () => true,
                count: this.tasksShort.length,
                selected: this.currentFilter === this.$t('page.designing.sideForm.all'),
            },
            {
                label: this.$t('page.designing.sideForm.inWork'),
                value: (task: DesignTask) => task.status === DesignTaskStatus.inWork,
                count: this.tasksShort.filter(({ status }) => status === DesignTaskStatus.inWork).length,
                selected: this.currentFilter === this.$t('page.designing.sideForm.inWork'),
                statusId: DesignTaskStatus.inWork,
            },
            {
                label: this.$t('page.designing.sideForm.onChecking'),
                value: (task: DesignTask) => task.status === DesignTaskStatus.onChecking,
                count: this.tasksShort.filter(({ status }) => status === DesignTaskStatus.onChecking).length,
                selected: this.currentFilter === this.$t('page.designing.sideForm.onChecking'),
                statusId: DesignTaskStatus.onChecking,
            },
            {
                label: this.$t('page.designing.sideForm.completed'),
                value: (task: DesignTask) => task.status === DesignTaskStatus.completed,
                count: this.tasksShort.filter(({ status }) => status === DesignTaskStatus.completed).length,
                selected: this.currentFilter === this.$t('page.designing.sideForm.completed'),
                statusId: DesignTaskStatus.completed,
            },
            {
                label: this.$t('page.designing.sideForm.overdue'),
                value: (task: DesignTask) => this.isTaskOverdue(task),
                count: this.tasksShort.filter((task) => this.isTaskOverdue(task)).length,
                selected: this.currentFilter === this.$t('page.designing.sideForm.overdue'),
                statusId: DesignTaskStatus.overdue,
            },
        ];
    }

    set filters(filters: DesignTaskFilter[]) {
        this.currentFilter = (filters.find(({ selected }) => selected) ?? first(this.filters)!).label;
        this.currentFilterStatus = (filters.find(({ selected }) => selected) ?? first(this.filters)!)?.statusId;
    }

    isTaskOverdue(task: DesignTask | TaskShort) {
        const { status, deadline } = task;
        if (!deadline || status === DesignTaskStatus.completed || status === DesignTaskStatus.onChecking) return false;

        return isDateOverdue(deadline);
    }

    get topFiltersCount() {
        return Object.values(this.topFilters).filter((value) => this.filterValueIsNotEmpty(value)).length;
    }

    filterValueIsNotEmpty(value: string | number | number[]): boolean {
        return typeof value === 'number' ? !!value : !isEmpty(value);
    }

    toggleTopFilter() {
        this.showTopFilter = !this.showTopFilter;
    }

    initFiltersFromRouteParams() {
        if (this.$route.params.filters) Object.assign(this.topFilters, JSON.parse(this.$route.params.filters));
    }

    getOptionsFromEnum(enumObj: any, localizationOptions: string) {
        return Object.entries(enumObj)
            .filter((entry) => this.$te(`${localizationOptions}.${entry[0]}`))
            .map((entry) => {
                const [name, value] = entry;
                return {
                    name: (!this.$t(`${localizationOptions}.${name}`)
                        ? name
                        : this.$t(`${localizationOptions}.${name}`)) as string,
                    id: value as number,
                };
            });
    }

    async initFiltersList() {
        const designOptions = await ErrorService.savedResponse(getTasksFilters, null);

        if (designOptions) {
            this.topFilterOptions = {
                ...designOptions,
                taskStatuses: [],
                executorStatuses: [],
            };
            this.topFilterOptions.taskStatuses = this.getOptionsFromEnum(
                DesignTaskStatus,
                'page.designing.sideForm.status',
            ).slice(1);
            this.topFilterOptions.executorStatuses = this.getOptionsFromEnum(
                DesignTaskStatusExecutor,
                'page.designing.sideForm.statusExecutor',
            ).filter((status) =>
                [
                    DesignTaskStatusExecutor.inWork,
                    DesignTaskStatusExecutor.commentsFormed,
                    DesignTaskStatusExecutor.agreed,
                    DesignTaskStatusExecutor.accepted,
                ].includes(status.id),
            );
        }

        this.topFiltersList = [
            new Filter('cities', this.$t('city'), [], FilterPanelSlotType.Select, {
                ...this.selectorBind,
                options: this.topFilterOptions?.cities,
            }),
            new Filter(
                'createdDateFrom',
                this.$t('page.designing.filter.createdDateFrom'),
                '',
                FilterPanelSlotType.DatePicker,
                { isClearable: true },
            ),
            new Filter(
                'createdDateTo',
                this.$t('page.designing.filter.createdDateTo'),
                '',
                FilterPanelSlotType.DatePicker,
                { isClearable: true },
            ),
            new Filter('taskStatus', this.$t('page.designing.filter.taskStatus'), [], FilterPanelSlotType.Select, {
                ...this.selectorBind,
                options: this.topFilterOptions?.taskStatuses,
                multiple: false,
            }),
            new Filter(
                'executorStatus',
                this.$t('page.designing.filter.executorStatus'),
                [],
                FilterPanelSlotType.Select,
                {
                    ...this.selectorBind,
                    options: this.topFilterOptions?.executorStatuses,
                    multiple: false,
                },
            ),
            new Filter('projects', this.$t('project'), [], FilterPanelSlotType.Select, {
                ...this.selectorBind,
                options: this.topFilterOptions?.projects,
            }),
            new Filter(
                'deadlineFrom',
                this.$t('page.designing.filter.deadlineFrom'),
                '',
                FilterPanelSlotType.DatePicker,
                { isClearable: true },
            ),
            new Filter('deadlineTo', this.$t('page.designing.filter.deadlineTo'), '', FilterPanelSlotType.DatePicker, {
                isClearable: true,
            }),
            new Filter('authors', this.$t('page.designing.filter.author'), [], FilterPanelSlotType.Select, {
                ...this.selectorBind,
                options: this.topFilterOptions?.authors,
            }),
            new Filter('executors', this.$t('page.designing.filter.executor'), [], FilterPanelSlotType.Select, {
                ...this.selectorBind,
                options: this.topFilterOptions?.executors,
            }),
            Filter.emptyElement(),
            Filter.emptyElement(),
            Filter.emptyElement(),
            Filter.applyButton(),
            Filter.clearButton(),
        ];
    }

    async created() {
        await this.initFiltersList();
    }
}
