var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"design-task__executors-list"},_vm._l((_vm.executors),function(executor,index){return _c('div',{key:index,staticClass:"design-task__executors-item"},[_c('div',{staticClass:"design-task__executors-info"},[_c('div',{staticClass:"design-task__executors-user",class:{ 'design-task__executors-user--transparent': executor.affiledBy?.length }},[_c('VUserRound',{attrs:{"user":executor}}),_c('p',{staticClass:"design-task__executors-name"},[_vm._v(" "+_vm._s(executor.name)+" ")])],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideActionsList),expression:"hideActionsList"}],staticClass:"design-task__executors-status",class:{
                    'design-task__executors-status--green': !_vm.isTaskOverdueExecutor(executor.taskInfo?.status),
                    'design-task__executors-status--red': _vm.isTaskOverdue,
                    'design-task__executors-status--clickable': _vm.isUserTaskAuthor,
                },on:{"click":function($event){return _vm.showActionsList({ executorId: executor.id, mouseEvent: $event, isPerformButtonTaped: false })}}},[_c('p',{staticClass:"design-task__executors-status-name design-task__status",class:{
                        'design-task__status--green': !_vm.isTaskOverdueExecutor(executor.taskInfo?.status),
                        'design-task__status--red': _vm.isTaskOverdue,
                    }},[_vm._v(" "+_vm._s(_vm.getStatusName(executor.taskInfo?.status))+" ")]),(_vm.isUserTaskAuthor)?_c('VerticalDotsSVG'):_vm._e()],1)]),(executor.affiledBy?.length)?_c('VDesignTaskSubstituting',{attrs:{"task":_vm.task,"user":executor,"substituted":"executors"}}):_vm._e(),(_vm.showProlongation(executor))?_c('div',{staticClass:"design-task__prolongation",on:{"click":function($event){return _vm.$emit('changeDeadlineAuthor', _vm.changeDeadlineAuthorId)}}},[_c('ProlongationExecutorSVG'),_c('p',[_vm._v(_vm._s(_vm.$t('page.designing.task.requestedProlongation')))]),_c('ProlongationArrowSVG')],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }