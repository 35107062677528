export enum DesignActionsEnum {
    Accept,
    BackToWork,
    Complete,
    CompleteForced,
    FormComments,
    Agree,
}

export default DesignActionsEnum;
