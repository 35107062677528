var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.remark.edit)?_c('VTextarea',{directives:[{name:"focus",rawName:"v-focus",value:('textarea'),expression:"'textarea'"}],ref:"textarea",staticClass:"designing-groups__remark-input",class:{
            'designing-groups__remark--selected': _vm.remark.selected,
        },attrs:{"attach-count":_vm.remark.files?.data.length,"max":500,"value":_vm.remark.description,"attach":"","self-send":""},on:{"attach":function($event){return _vm.$emit('attach-files', _vm.remark)},"input":function($event){return _vm.SET_REMARK_VALUE({ remark: _vm.remark, key: 'description', value: $event })},"send":_vm.updateRemarkChange}}):_c('div',{ref:"remarkContainer",staticClass:"designing-groups__remark",class:{
            'designing-groups__remark--selected': _vm.remark.selected,
            'designing-groups__remark--error': _vm.remark.error,
        },on:{"click":_vm.selectRemark}},[_c('div',{staticClass:"designing-groups__remark-name"},[_c('span',[_vm._v(" "+_vm._s(_vm.remarkTitle)+" ")]),(
                    _vm.canCompleteRemarks &&
                    !_vm.showSecondDrawing &&
                    _vm.showRemarkCompletion &&
                    _vm.remark.status === _vm.RemarkRejectionEnum.Created
                )?_c('label',{staticClass:"designing-groups__remark-button designing-groups__remark-button--complete",class:{ 'designing-groups__remark-button--disabled': !_vm.canCompleteRemarks }},[_c('span',[_vm._v(_vm._s(_vm.$t('perform')))]),_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('complete'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('complete'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],on:{"click":function($event){return _vm.completeRemark(_vm.remark, _vm.RemarkRejectionEnum.Completed)}}},[_c('RemarkCheckboxGreySVG')],1),_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('reject'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('reject'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],on:{"click":_vm.rejectRemark}},[_c('RemarkCrossGreySVG')],1)],1):_vm._e(),(_vm.showRemarkCompletion && _vm.remark.status === _vm.RemarkRejectionEnum.Completed)?_c('label',{staticClass:"designing-groups__remark-button designing-groups__remark-button--completed",class:{ 'designing-groups__remark-button--disabled': !_vm.canCompleteRemarks }},[_c('span',[_vm._v(_vm._s(_vm.$t('completed')))]),(_vm.canCompleteRemarks && !_vm.showSecondDrawing)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('cancel'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('cancel'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],on:{"click":function($event){return _vm.completeRemark(_vm.remark, _vm.RemarkRejectionEnum.Created)}}},[_c('RemarkCheckboxGreenSVG')],1):_vm._e()],1):_vm._e(),(_vm.showRemarkCompletion && _vm.remark.status === _vm.RemarkRejectionEnum.Rejected)?_c('label',{staticClass:"designing-groups__remark-button designing-groups__remark-button--rejected",class:{ 'designing-groups__remark-button--disabled': !_vm.canCompleteRemarks }},[_c('span',[_vm._v(_vm._s(_vm.$t('rejected')))]),(_vm.canCompleteRemarks && !_vm.showSecondDrawing)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('reset'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('reset'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],on:{"click":_vm.resetRemarkRejection}},[_c('RemarkCrossRedSVG')],1):_vm._e()],1):_vm._e()]),_c('p',{staticClass:"designing-groups__remark-description"},[_vm._v(_vm._s(_vm.remark.description))]),(_vm.remark.status === _vm.RemarkRejectionEnum.Rejected)?_c('div',{staticClass:"designing-groups__remark-rejection"},[(_vm.hasExecutorData(_vm.remark))?_c('div',{staticClass:"designing-groups__remark-rejection-column"},[_c('VUserRound',{attrs:{"user":_vm.remark.executor.data}})],1):_vm._e(),_c('div',{staticClass:"designing-groups__remark-rejection-column"},[_c('div',{staticClass:"designing-groups__remark-rejection-head"},[_c('p',{staticClass:"designing-groups__remark-rejection-title"},[_vm._v(" "+_vm._s(_vm.$t('page.designing.plans.form.remarkRejectionReasonTitle'))+" ")]),(_vm.canCompleteRemarks && !_vm.showSecondDrawing)?_c('RemarkRejectionSVG',{on:{"click":_vm.changeRemarkRejection}}):_vm._e()],1),_c('p',{staticClass:"designing-groups__remark-rejection-description"},[_vm._v(" "+_vm._s(_vm.remark.rejectDescription)+" ")])])]):_vm._e(),_c('div',{staticClass:"designing-groups__remark-tools"},[_c('div',{staticClass:"designing-groups__remark-author"},[_c('VUserRound',{attrs:{"user":_vm.remark.author.data}}),_c('span',[_vm._v(_vm._s(_vm._f("simpleTime")(_vm.remark.updatedAt))+" / "+_vm._s(_vm.remark.pageNumber)+" "+_vm._s(_vm.$t('page.designing.plans.form.pgs'))+" ")])],1),_c('div',{staticClass:"designing-groups__remark-actions"},[(_vm.hasAccess)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('chat'),
                        placement: 'bottom',
                        classes: 'designing-groups__remark-tooltip--bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('chat'),\n                        placement: 'bottom',\n                        classes: 'designing-groups__remark-tooltip--bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],staticClass:"designing-groups__remark-messages",attrs:{"color":"green"},on:{"click":_vm.openMessages}},[_c('ChatSVG'),(_vm.remark.unreadMessagesCount)?_c('div',{staticClass:"designing-groups__unreaded-count"},[_vm._v(" +"+_vm._s(_vm.remark.unreadMessagesCount)+" ")]):_vm._e()],1):_vm._e(),(_vm.hasAccess)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('page.designing.plans.form.attach'),
                        placement: 'bottom',
                        classes: 'designing-groups__remark-tooltip--bottom designing-groups__remark-tooltip-attach',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('page.designing.plans.form.attach'),\n                        placement: 'bottom',\n                        classes: 'designing-groups__remark-tooltip--bottom designing-groups__remark-tooltip-attach',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],staticClass:"designing-groups__remark-messages",attrs:{"color":"green"},on:{"click":function($event){return _vm.$emit('attach-files', _vm.remark)}}},[_c('AttachSVG'),(_vm.remark.files?.data.length)?_c('div',{staticClass:"designing-groups__unreaded-count"},[_vm._v(" "+_vm._s(_vm.remark.files.data.length)+" ")]):_vm._e()],1):_vm._e(),(_vm.canCloneRemarks)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('copy'),
                        placement: 'bottom',
                        classes: 'designing-groups__remark-tooltip--bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('copy'),\n                        placement: 'bottom',\n                        classes: 'designing-groups__remark-tooltip--bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set-clone', _vm.remark)}}},[_c('DocumentsSVG')],1):_vm._e(),(_vm.groups.length > 1 && _vm.editable)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('page.designing.plans.form.swap'),
                        placement: 'bottom',
                        classes: 'designing-groups__remark-tooltip--bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('page.designing.plans.form.swap'),\n                        placement: 'bottom',\n                        classes: 'designing-groups__remark-tooltip--bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.showGroupsListFor(_vm.remark.id)}}},[_c('SwapSVG')],1):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.showGroupsListFor),expression:"showGroupsListFor"}],staticClass:"designing-groups__list",class:{ 'designing-groups__list--visible': _vm.showRelocationListFor === _vm.remark.id }},_vm._l((_vm.groups),function(option,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(option.id !== _vm.group.id),expression:"option.id !== group.id"}],key:index,staticClass:"designing-groups__list-item",on:{"click":function($event){return _vm.changeRemarkGroup(option, _vm.group, _vm.remark)}}},[_vm._v(" "+_vm._s(_vm.$t('page.designing.plans.form.relocateIn'))+" «"+_vm._s(option.name)+"» ")])}),0),(_vm.editable)?_c('VButtonIcon',{directives:[{name:"permission",rawName:"v-permission",value:('update-task-designs'),expression:"'update-task-designs'"},{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('page.designing.plans.form.editRemark'),
                        placement: 'left',
                        classes: 'designing-groups__remark-tooltip',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('page.designing.plans.form.editRemark'),\n                        placement: 'left',\n                        classes: 'designing-groups__remark-tooltip',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],attrs:{"color":"green"},on:{"click":function($event){return _vm.toggleEdit(_vm.remark)}}},[_c('EditSVG')],1):_vm._e(),(_vm.editable)?_c('VButtonIcon',{directives:[{name:"permission",rawName:"v-permission",value:('update-task-designs'),expression:"'update-task-designs'"},{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('page.designing.plans.form.removeRemark'),
                        placement: 'left',
                        classes: 'designing-groups__remark-tooltip',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('page.designing.plans.form.removeRemark'),\n                        placement: 'left',\n                        classes: 'designing-groups__remark-tooltip',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('remove', _vm.group, _vm.remark)}}},[_c('TrashSVG')],1):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }