enum DesignStageEnum {
    MasterPlan = 1, // Мастер-план
    PlanningSurveying, // Проект планировки и межевания территории
    Sketch, // Эскизный проект
    ProjectDocumentation, // Проектная документация
    WorkDocumentation, // Рабочая документация
    Landscaping, // Проект ландшафтного дизайна и благоустройства
    CommonAreasDesign, // Дизайн-проект мест общего пользования
    PreliminaryMaterials, // Предварительные материалы
    Research, // Изыскания
}

export default DesignStageEnum;
