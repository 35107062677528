import { createApiInstance } from '@/ship/Http';
import ErrorService from './ErrorService';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { toNumber } from 'lodash';
import ModalService from '@/ship/Services/ModalService';
import vueI18n from '@/plugins/VueI18n';

export interface IFolder {
    id: string;
    depth: number;
    name: string | null;
    authorId: string | null;
    parentId: string | null;
    skeletonEntityId: string | null;
    ancestorIds: string[];

    allowCreateDirectory: boolean;
    allowUploadDocuments: boolean;

    createdAt: string;
    updatedAt: string;

    metadata?: Array<{ key: string; value: string }>;
}

export interface IDocument {
    id: string;
    depth: number;
    name: string | null;
    authorId: string | null;
    parentId: string | null;
    ancestorIds: string[];

    agreed: boolean;
    disabled: boolean;
    downloadUri: string;

    version: IDocumentVersion;
    versions: IDocumentVersion[];

    createdAt: string;
    updatedAt: string;

    metadata?: Array<{ key: string; value: string }>;
}

export interface IDocumentVersion {
    index: number;
    isActive: boolean;

    document: IDocumentInfo;
    documentId: string;
    downloadUri: string;

    createdAt: string;
    updatedAt: string;

    metadata: Record<string, string>;
}

export interface IDocumentInfo {
    authorId: string | null;
    extension: string;
    fullName: string;
    name: string;
    size: number;
    type: string;
}

export interface IDirectory {
    ancestors: IFolder[];
    childrenDirectories: IFolder[];
    childrenDocuments: IDocument[];
    directory: IFolder | null;
}

export interface IRequirements {
    canDownload: boolean;
    requireAcceptance: IRequirement[];
}

export interface IRequirement {
    codeName: string;
    buttonName: string;
    requirementText: string;
    acceptanceEndpoint: string;
    acceptanceEndpointMethod: string;
}

export const TCloudHttp = createApiInstance(process.env.VUE_APP_CLOUD_URL ?? '', true);
export const TanglViewerUrl = process.env.VUE_APP_CLOUD_VIEWER_URL;

export enum StampType {
    InProductionOfWorksStamp = 1,
}

// API Documentation: https://bim.talan.group/swagger/index.html

export class TCloudService {
    public static makeUrlLinkDownloadable(link: string) {
        return link.replace('/documents', '/v1/documents').replace(/download\/.+$/, 'download');
    }

    public static createFileUid({ id, version }: Pick<IDocument, 'id' | 'version'>) {
        return `${id}__${version.index}`;
    }

    public static parseFileUid(uid: string) {
        const [documentId, versionIndex] = uid.split('__');

        return { documentId, versionIndex: toNumber(versionIndex) };
    }

    public static getRootDirectory() {
        return ErrorService.savedResponse(
            async () => (await TCloudHttp.get<ITransformedValue<IDirectory>>('/v1/directories/root/fetch')).data.data,
            null,
        );
    }

    public static getDirectory(directoryId: string) {
        return ErrorService.savedResponse(
            async () =>
                (await TCloudHttp.get<ITransformedValue<IDirectory>>(`/v1/directories/${directoryId}/fetch`)).data.data,
            null,
        );
    }

    public static getDocument(documentId: string) {
        return ErrorService.savedResponse(
            async () => (await TCloudHttp.get<ITransformedValue<IDocument>>(`/v1/documents/${documentId}`)).data.data,
            null,
        );
    }

    public static updateDocument(documentId: string, data: { name: string }) {
        return ErrorService.savedResponse(
            async () =>
                (await TCloudHttp.put<ITransformedValue<IDocument>>(`/v1/documents/${documentId}`, data)).data.data,
            null,
        );
    }

    public static getAllDocuments(ids: string[]) {
        return ErrorService.savedResponse(
            async () =>
                (
                    await TCloudHttp.post<ITransformedValue<IDocument[]>>(`/v1/documents/all/fetch`, {
                        match: {
                            ids,
                        },
                    })
                ).data.data,

            [],
        );
    }

    public static putStampOnDocumentVersion(
        documentId: string,
        versionIndex: number,
        stampType = StampType.InProductionOfWorksStamp,
    ) {
        return TCloudHttp.put<ITransformedValue<IDocumentVersion>>(
            `/v1/documents/${documentId}/versions/${versionIndex}/stamp`,
            { type: stampType },
            { headers: { 'Content-Type': 'application/json' } },
        );
    }

    /**
     * Деактуализирует версию со штампом
     */
    public static deactualizeDocumentVersion(documentId: string, versionIndex: number) {
        return TCloudHttp.post(`/v1/documents/${documentId}/versions/${versionIndex}/destroy`);
    }

    public static headDocumentVersion(documentId: string, versionIndex: number) {
        return TCloudHttp.post<ITransformedValue<IDocument>>(
            `/v1/documents/${documentId}/versions/${versionIndex}/head`,
        );
    }

    /**
     * Блокирует возможность изменять документ
     */
    public static agreeDocument(documentId: string) {
        return TCloudHttp.post(`/v1/documents/${documentId}/agree`);
    }

    /**
     * Разблокирует возможность изменять документ
     */
    public static disagreeDocument(documentId: string) {
        return TCloudHttp.post(`/v1/documents/${documentId}/disagree`);
    }

    /**
     * Получить политики перед загрузкой документа
     */
    public static async downloadRequirements(documentId: string, versionIndex: number) {
        const {
            data: { data: requirements },
        } = await TCloudHttp.get<ITransformedValue<IRequirements>>(
            `/v1/documents/${documentId}/versions/${versionIndex}/download-requirements`,
        );
        return requirements;
    }

    /**
     * Принять политики для дальнейшей загрузки документа
     */
    public static acceptRequirements(documentId: string, versionIndex: number) {
        return TCloudHttp.post(
            `/v1/documents/${documentId}/versions/${versionIndex}/requirements/document-receipt-requirement/accept`,
        );
    }

    public static async acceptAllRequirements(requirements: IRequirement[], documentId: string, versionIndex: number) {
        if (!requirements.length) return false;

        return requirements.reduce(async (isAllConfirmed, requirement) => {
            const isConfirmed = await ModalService.confirm({
                title: vueI18n.t('components.tCloudModal.tCloudNotification'),
                description: requirement.requirementText,
                labelPositive: requirement.buttonName,
                onConfirm: async () => this.acceptRequirements(documentId, versionIndex),
            });

            return (await isAllConfirmed) && isConfirmed;
        }, Promise.resolve(true));
    }

    /**
     * Скопировать версию из документа, расположенного в TCloud в другой документ в TCloud
     * @param sourceId      id документа источника
     * @param versionIndex  index версия документа для копирования (-1 - последняя)
     * @param destinationId id документа цель
     * @returns
     */
    public static copyVersion(sourceId: string, versionIndex: number, destinationId: string) {
        return ErrorService.savedResponse(
            async () =>
                (
                    await TCloudHttp.post<ITransformedValue<IDocument>>(
                        `/v1/documents/${sourceId}/versions/${versionIndex}/copy-to/${destinationId}`,
                    )
                ).data.data,
            null,
        );
    }

    /**
     * Получить URL TanglViewer для встраивания в iFrame
     * @param document документ из TCloud
     */
    public static getTanglViewerUrl(document: IDocument) {
        const tanglId = document.version.metadata.tanglVersionId ?? document.version.metadata.tanglVersionId;
        const modelIds = document.metadata ? document.metadata.map((entry) => entry.value) : [tanglId];

        return TanglViewerUrl + modelIds.map((id) => `ids=${id}`).join('&');
    }
}

export default TCloudService;
