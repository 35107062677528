export enum DesigningTools {
    Cursor = 'cursor',
    CursorPicker = 'cursorPicker',
    Rectangle = 'rectangle',
    Hand = 'hand',
}

export enum DesigningHotkeys {
    F1 = DesigningTools.Cursor,
    F2 = DesigningTools.CursorPicker,
    F3 = DesigningTools.Rectangle,
    F4 = DesigningTools.Hand,
}

export default DesigningTools;
