export enum DesignTaskStatus {
    overdue,
    inWork,
    onChecking,
    completed,
}

export default DesignTaskStatus;

export enum DesignTaskStatusExecutor {
    inWork = 1,
    onCheck,
    accepted,
    production,
    cancelled,
    agreed,
    commentsFormed,
}
