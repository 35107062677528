enum DesignStatusEnum {
    fixing = 1,
    checking = 2,
    adopted = 3,
    inProduction = 4,
    cancel = 5,
    requestReturn = 8, // Запрос на отправку в работу
}

export default DesignStatusEnum;
